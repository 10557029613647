import PropTypes from 'prop-types'
import React from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import MuiDialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import TextField from '@mui/material/TextField'
import FILE_TYPE_DEFS from '../../../store/objects/data/fileTypes.json'
import { styled } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  display: 'grid',
  width: 400,
  gap: theme.spacing(1),
  gridTemplateColumns: '1fr'
}))

const FILE_TYPES = FILE_TYPE_DEFS.default.map(def => def.value)

SaveQueryDialog.errorMessages = {
  name: 'Required'
}

SaveQueryDialog.fieldNames = {
  name: 'name',
  description: 'description'
}

export default function SaveQueryDialog ({
  type,
  onSave,
  onCancel,
  model
}) {
  const {
    handleSubmit,
    formState: { errors, isValid },
    register,
    getValues,
    setFocus,
    control
  } = useForm({
    mode: 'onChange',
    values: {
      [SaveQueryDialog.fieldNames.name]: model?.name || '',
      [SaveQueryDialog.fieldNames.description]: model?.description || ''
    }
  });

  const typeLabel = React.useMemo(
    () => FILE_TYPE_DEFS.default.find(def => def.value === type).label,
    [type]
  )

  const handleCancel = () => {
    onCancel()
  }

  const handleSave = () => {
    if (isValid) {
      const newModel = getValues()

      onSave({
        ...model,
        ...newModel
      })
    }
  }

  React.useEffect(() => {
    setTimeout(() => {
      setFocus(SaveQueryDialog.fieldNames.name);
    }, 1)
  }, [setFocus]);

  return (
    <Dialog open>
      <DialogTitle
        data-testid='label-title'
        sx={{ mt: 5 }}
      >{model.guid ? 'Edit' : 'New'} Query</DialogTitle>

      <DialogContent>
        <Typography
          data-testid='label-type'
          sx={{ mb: 5 }}
        >Type: {typeLabel}</Typography>
        <Controller
          name={SaveQueryDialog.fieldNames.name}
          control={control}
          rules={{ required: SaveQueryDialog.errorMessages.name }}
          render={({ field: { ref, ...field } }) => (
            <TextField
            {...field}
            inputRef={ref}
            inputProps={{
              'data-testid': 'textfield-name'
            }}
            label='Name'
            helperText='Required'
            error={!!errors[SaveQueryDialog.fieldNames.name]}
          />
          )}
        />
        <TextField
          {...register(SaveQueryDialog.fieldNames.description)}
          sx={{ mt: 5 }}
          inputProps={{
            'data-testid': 'textfield-description'
          }}
          label='Description'
          helperText={errors[SaveQueryDialog.fieldNames.description] && errors[SaveQueryDialog.fieldNames.description].message}
        />
      </DialogContent>

      <DialogActions>
        <Button
          data-testid='button-cancel'
          color='primary'
          onClick={handleCancel}
        >Cancel</Button>

        <Button
          data-testid='button-save'
          color='primary'
          disabled={!isValid}
          onClick={handleSubmit(handleSave)}
        >Save</Button>
      </DialogActions>
    </Dialog>
  )
}

SaveQueryDialog.propTypes = {
  type: PropTypes.oneOf(FILE_TYPES).isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

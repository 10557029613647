import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import CircularProgress from '@mui/material/CircularProgress'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'
import calculationApi from '../../../../store/objects/calculationApi'
import projectApi from '../../../../store/objects/projectApi'
import stepFunctionsApi from '../../../../store/objects/stepFunctionsApi'
import SelectableListView from '../SelectableListView'
import Parameters from './Parameters'
import RunStudy from './RunStudy'
import Ibnr from './Ibnr'
import { styled } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { updateRunSetupAction } from '../../../../store/pages/calculation/actions'
import { getJobHistory } from '../../../../store/pages/calculation/async'
import { redePostCalChecks } from '../../../../store/pages/postCalculation'
import { loadedProjectDataAction } from '../../../../store/pages/project/actions'
import { getAccess } from '../../../../store/pages/project/async'
import { FormProvider, useForm } from 'react-hook-form';
import uuid from '../../../../uuid'

const DEFAULT_PARAMS = {
  applyIncidenceRateCap: false,
  addMonthlyAggregation: false,
  applySubstandardFlatExtra: false,
  addBuhlmannConstants: false,
  applyExpectedLapseRates: false,
  includeWarnings: false,
  performMonteCarlo: false,
  calculateIbnr: false,
  incidenceRate: -1,
  observationDate: null,
  periodStartDate: null,
  periodEndDate: null,
  treatyRestrictions: '',
  policyRestrictions: '',
  powerBiReportUrl: ''
}

const DEFAULT_IBNR_PARAMS = {
  asOfDate: '',
  monthsPrior: 120,
  aggregators: [],
  caseNumber: '0'
}

const INITIAL_JOB = {
  totalTime: null,
  fileProcessingTime: null,
  xpCalculatorRunTime: null,
  workWorthPerNode: null,
  numberOfNodes: null,
  dataLakeLoadTime: null,
  valPolicySize: null,
  valTerminateSize: null,
  riskHistorySize: null,
  treatySize: null,
  basisSize: null,
  packageName: null,
  errorMessage: '',
  createdDate: null,
  deletedDate: null,
  tableNames: []
}

/* istanbul ignore next */
const CONFIG_AGGREGATORS_VIEW = [
  {
    key: 'AGGREGATOR_NAME',
    label: 'Name',
    size: 100
  },
  {
    key: 'POPULARITY',
    label: 'Popularity',
    size: 100,
    render: value => (
      <LinearProgress
        variant='determinate'
        value={value}
      />
    )
  },
  {
    key: 'FIELDS_USED',
    label: 'Fields',
    size: 200
  },
  {
    key: 'IBNR_ENABLED',
    label: 'IBNR Enabled',
    size: 100
  },
  {
    key: 'DESCRIPTION',
    label: 'Description',
    size: 200,
    render: value => <Tooltip title={value}><span></span></Tooltip>
  }
]

/* istanbul ignore next */
const CONFIG_EXPECTED_BASIS_VIEW = [
  {
    key: 'caseNumber',
    label: 'Case #',
    size: 100
  },
  {
    key: 'name',
    label: 'Name',
    size: 200
  },
  {
    key: 'description',
    label: 'Description',
    size: 200
  }
]

const AGG_SEARCH_KEYS = ['KEY_ALIAS']
const BASIS_SEARCH_KEYS = ['caseNumber', 'name', 'description']

const SpinnerContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginTop: '25%',
  marginLeft: '50%'
})

const SpinnerContent = styled('div')({
  display: 'flex',
  flexFlow: 'column nowrap',
  alignItems: 'center',
  transform: 'translate(-50%, -50%)'
})

function flattenErrors (errors) {
  return Object.keys(errors)
    .map(errorName => errors[errorName].message)
    .filter(errorMessage => typeof errorMessage === 'string')
}

async function fetchDates (project) {
  const { data } = await calculationApi.getRunStudyValues(project.id)
  const endDateParts = data[0].profiledate.substring(0, 10).split('-')
  const endDate = new Date(Number(endDateParts[0]), Number(endDateParts[1]), 0)

  return {
    observationDate: data[0].profiledate.substring(0, 10),
    periodStartDate: data[1].profiledate.substring(0, 10),
    periodEndDate: endDate.toISOString().substring(0, 10)
  }
}

async function fetchProjectInfo (project) {
  const [dates, jobs, aggregators] = await Promise.all([
    fetchDates(project),
    calculationApi.fetchJobs(project.id),
    calculationApi.fetchAggregators()
  ])

  return {
    dates,
    jobs,
    aggregators
  }
}

function Spinner () {
  return (
    <SpinnerContainer data-testid='spinner-loading'>
      <SpinnerContent>
        <Typography>Loading</Typography>
        <CircularProgress />
      </SpinnerContent>
    </SpinnerContainer>
  )
}

const MemoizedSpinner = React.memo(Spinner)

export default function Study () {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [disableActionButton, setDisableActionButton] = React.useState(false)
  const [loaded, setLoaded] = React.useState(false)
  const [jobs, setJobs] = React.useState(null)
  const [aggregators, setAggregators] = React.useState(null)
  const [deletingJobIds, setDeletingJobIds] = React.useState([])
  const [updatingJobId, setUpdatingJobId] = React.useState(null)
  const [submittedJob, setSubmittedJob] = React.useState(null)
  const [postCalcProgress, setPostCalcProgress] = React.useState(null)
  const [selectedBases, setSelectedBases] = React.useState([])
  const [selectedAggregators, setSelectedAggregators] = React.useState([])

  const user = useSelector((store) => store.user)
  const project = useSelector((store) => store.project)
  const currentTab = useSelector((store) => store.calculation.currentTab)
  const postCalculationCheckRunning = useSelector((store) => store.postCalc.postCalculationCheckRunning)
  const bases = useSelector((store) => store.refData.basis)
  const valTermColumns = project.formatFiles.find(file => file.type === 'val terminated').columns

  const access = getAccess(
    project.sharedWithIds,
    project.ownerId,
    project.studyType,
    user.role,
    user.ownerId,
    'CALCULATION'
  )

  const paramsForm = useForm({ mode: 'onChange', values: DEFAULT_PARAMS });
  const ibnrForm = useForm({ mode: 'onChange', values: DEFAULT_IBNR_PARAMS });

  const { isValid: ibnrFormIsValid, errors: ibnrFormErrors } = ibnrForm.formState;
  const { isValid: paramsFormIsValid, errors: paramsFormErrors } = paramsForm.formState;

  const ibnrFormValues = ibnrForm.getValues()
  const paramsFormValues = paramsForm.getValues()

  const ibnrFormWatch = ibnrForm.watch
  const paramsFormWatch = paramsForm.watch

  React.useEffect(() => {
    const ibnrFormSubscription = ibnrFormWatch((value, { name, type }) => {
      dispatch({
        ...updateRunSetupAction,
        payload: {
          ibnr: value
        }
      })
    })
    return () => {
      ibnrFormSubscription.unsubscribe()
    }
  }, [ibnrFormWatch, dispatch])

  React.useEffect(() => {
    const paramsFormSubscription = paramsFormWatch((value, { name, type }) => {
      dispatch({
        ...updateRunSetupAction,
        payload: {
          parameters: value
        }
      })
    })

    return () => {
      paramsFormSubscription.unsubscribe()
    }
  }, [paramsFormWatch, dispatch])

  React.useEffect(() => {
    dispatch({
      ...updateRunSetupAction,
      payload: {
        bases: selectedBases,
        aggregators: selectedAggregators
      }
    })
  }, [selectedBases, selectedAggregators, dispatch])

  const errorMessage = [
    !access ? 'You do not have access to this feature' : '',
    ...(flattenErrors(paramsFormErrors).concat(flattenErrors(ibnrFormErrors)))
  ].filter(item => item)[0] || ''

  const updateFormWithParams = async (project, paramsForm, ibnrForm) => {
    const res = await fetchProjectInfo(project)
    const latestJob = res.jobs[0] || null
    const running = latestJob ? !latestJob.finished : false
    const finishedJobs = running ? res.jobs.slice(1) : res.jobs
    const latestParams = latestJob?.parameters || null
    const latestIBNRParams = latestParams?.calculateIbnr
      ? {
          asOfDate: latestJob?.asOfDate,
          aggregators: latestJob?.ibnrAggregators,
          monthsPrior: latestJob?.monthsPrior,
          caseNumber: latestJob?.caseNumber
        }
      : null

    const params = {
      ...DEFAULT_PARAMS,
      ...latestParams
    }

    const ibnrParams = { ...DEFAULT_IBNR_PARAMS, ...latestIBNRParams }

    setJobs(finishedJobs)
    setAggregators(res.aggregators)
    setSelectedBases(latestJob?.bases ?? [])
    setSelectedAggregators(latestJob?.aggregators ?? [])

    paramsForm.reset({
      ...params,
      observationDate: params.observationDate ?? res.dates.observationDate,
      periodStartDate: params.periodStartDate ?? res.dates.periodStartDate,
      periodEndDate: params.periodEndDate ?? res.dates.periodEndDate,
      powerBiReportUrl: params.powerBiReportUrl ?? latestJob?.parameters?.powerBiReportUrl ?? null
    });
    paramsForm.trigger();

    ibnrForm.reset({
      ...ibnrParams,
      asOfDate: params.observationDate ?? res.dates.observationDate
    });
    ibnrForm.trigger();

    return { running, latestJob }
  }

  const waitForJob = React.useCallback(async job => {
    setSubmittedJob(job)

    try {
      const res = await stepFunctionsApi.waitForCompletion(job.executionArn)

      if (res.status === 'ABORTED') {
        throw new Error('Aborted')
      }

      const output = res.output ? JSON.parse(res.output) : ''
      const outputFiles = output?.fileNames ?? []
      const isIBNRSuccess = job.ibnr ? output?.ibnrSuccess : null

      if (outputFiles.length === 0) {
        throw new Error('No output detected')
      }

      const updatedJob = isIBNRSuccess
        ? {
            ...job,
            finished: true,
            tableNames: [...outputFiles]
          }
        : {
            ...job,
            finished: true,
            tableNames: [...outputFiles],
            isIBNRSuccess
          }

      setJobs(prev => {
        const hasJobId = prev.find(prevJob => prevJob.id === updatedJob.id)
        return hasJobId ? prev : [updatedJob, ...prev]
      })
      setSubmittedJob(null)

      return true
    } catch (err) {
      const isIBNRSuccess = job.ibnr ? false : null
      setJobs(prev => {
        const hasJobId = prev.find(prevJob => prevJob.id === job.id)
        return hasJobId
          ? prev
          : [{
              ...job,
              finished: true,
              errorMessage: err.message,
              tableNames: [],
              isIBNRSuccess
            },
            ...prev
            ]
      })
      setSubmittedJob(null)
      return false
    }
  }, [setSubmittedJob, setJobs])

  const handlePopulate = React.useCallback(job => {
    const params = job.parameters
    const ibnr = job.ibnr ?? {
      asOfDate: job.asOfDate,
      aggregators: job.ibnrAggregators,
      monthsPrior: job.monthsPrior,
      caseNumber: job.caseNumber
    }

    setSelectedAggregators(job.aggregators);
    setSelectedBases(job.bases);

    paramsForm.reset(params);
    ibnrForm.reset(ibnr);
  }, [paramsForm, ibnrForm, setSelectedAggregators, setSelectedBases])

  const handleDeleteJob = React.useCallback(async job => {
    setDeletingJobIds(prev => [...prev, job.id])

    await calculationApi.deleteJobRun(project.id, job.id)

    const updatedProject = await projectApi.getProject(project.id)

    dispatch({
      ...loadedProjectDataAction,
      payload: {
        project: updatedProject,
        navigation: {}
      }
    })

    setDeletingJobIds(prev => prev.filter(targetId => targetId !== job.id))
    setJobs(prev => prev.filter(item => item.id !== job.id))
  }, [
    project.id,
    dispatch
  ])

  const handleToggleJob = React.useCallback(async job => {
    const isOfficial = project.officialJobId === job.id
    const officialJobId = isOfficial ? null : job.id

    const modifiedProject = {
      ...project,
      officialJobId
    }

    setUpdatingJobId(job.id)

    const updatedProject = await projectApi.putProject(modifiedProject)

    dispatch({
      ...loadedProjectDataAction,
      payload: {
        project: updatedProject,
        navigation: {}
      }
    })

    setUpdatingJobId(() => null)
  }, [
    project,
    dispatch
  ])

  const handleSubmit = React.useCallback(async () => {
    ibnrForm.trigger();
    paramsForm.trigger();
    if ((!ibnrFormIsValid) || (!paramsFormIsValid)) {
      return
    }

    const model = {
      id: uuid(),
      bases: selectedBases,
      aggregators: selectedAggregators,
      parameters: paramsFormValues,
      ibnr: ibnrFormValues
    }

    setDisableActionButton(true)

    const res = await calculationApi.submitJob(user, project, model)

    setDisableActionButton(false)

    const job = {
      ...model,
      ...INITIAL_JOB,
      executionArn: res.executionArn
    }

    if (await waitForJob(job)) {
      dispatch(getJobHistory)
        .then(() => {
          updateFormWithParams(project, paramsForm, ibnrForm)
            .then(() => {
              dispatch(redePostCalChecks(job.id))
                .then(() => {
                  navigate(`/postcalculation/${project.id}`)
                })
            })
        })
    }
  }, [
    user,
    project,
    dispatch,
    navigate,
    waitForJob,
    paramsForm,
    ibnrForm,
    ibnrFormValues,
    paramsFormValues,
    selectedAggregators,
    selectedBases,
    paramsFormIsValid,
    ibnrFormIsValid
  ])

  const handleSelectedAggregatorsOnChange = React.useCallback(selectedAggregators => {
    setSelectedAggregators(selectedAggregators)
  }, [setSelectedAggregators])

  const handleSelectedBasesOnChange = React.useCallback(selectedBases => {
    setSelectedBases(selectedBases)
  }, [setSelectedBases])

  const handleAbort = React.useCallback(async () => {
    setDisableActionButton(true)
    await calculationApi.abortJob(project.id, submittedJob.id)
    setSubmittedJob(null)
    setDisableActionButton(false)
  }, [project.id, submittedJob?.id])

  React.useEffect(() => {
    if (postCalculationCheckRunning) {
      setPostCalcProgress('Running')
    }
  }, [postCalculationCheckRunning])

  React.useEffect(() => {
    dispatch(getJobHistory).then(async () => {
      const { running, latestJob } = await updateFormWithParams(project, paramsForm, ibnrForm)

      setLoaded(true)

      if (running) {
        waitForJob(latestJob)
      }
    })
  }, [dispatch, project, waitForJob, paramsForm, ibnrForm])

  if (!loaded) {
    return <MemoizedSpinner />
  }

  return (
    <TabContext value={currentTab}>
      <TabPanel value='Parameters'>
      <FormProvider {...paramsForm}>
        <Parameters
          data-testid='parameters'
          name='parameters'
          access={access}
        />
        </FormProvider>
      </TabPanel>

      <TabPanel value='Aggregators'>
        <SelectableListView
          data-testid='list-view-aggregators'
          name='aggregators'
          maxSelectCount={50}
          sortKey='POPULARITY'
          sortOrder='desc'
          selectKey='KEY_ALIAS'
          searchKeys={AGG_SEARCH_KEYS}
          config={CONFIG_AGGREGATORS_VIEW}
          items={aggregators}
          selectedItems={selectedAggregators}
          onChange={handleSelectedAggregatorsOnChange}
        />
      </TabPanel>

      <TabPanel value='Expected Bases'>
        <SelectableListView
          data-testid='list-view-bases'
          name='bases'
          sortKey='caseNumber'
          selectKey='caseNumber'
          searchKeys={BASIS_SEARCH_KEYS}
          config={CONFIG_EXPECTED_BASIS_VIEW}
          items={bases}
          selectedItems={selectedBases}
          onChange={handleSelectedBasesOnChange}
        />
      </TabPanel>

      <TabPanel value='IBNR'>
        <FormProvider {...ibnrForm}>
          <Ibnr
            data-testid='ibnr'
            name='ibnr'
            allBases={bases}
            selectedBases={selectedBases}
            selectedAggregators={selectedAggregators}
            allAggregators={aggregators}
            valTermColumns={valTermColumns}
            access={access}
          />
        </FormProvider>
      </TabPanel>

      <TabPanel value='Run Study'>
        <RunStudy
          data-testid='study'
          disableActionButton={disableActionButton}
          officialJobId={project.officialJobId}
          errorMessage={errorMessage}
          updatingId={updatingJobId}
          deletingIds={deletingJobIds}
          jobs={jobs}
          value={{
            bases: selectedBases,
            aggregators: selectedAggregators,
            parameters: paramsFormValues,
            ibnr: ibnrFormValues
          }}
          submittedJob={submittedJob}
          postCalcProgress={postCalcProgress}
          onPopulate={handlePopulate}
          onAbort={handleAbort}
          onSubmit={handleSubmit}
          onToggle={handleToggleJob}
          onDelete={handleDeleteJob}
        />
      </TabPanel>
    </TabContext>
  )
}
